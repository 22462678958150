body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

@keyframes move {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-element {
  animation-fill-mode: both;
  animation-play-state: paused;
}

.move {
  animation: move 1s ease-in-out;
}

.fade {
  animation: fade 1s ease-in-out;
}

.infinite-fade {
  animation: fadeInOut infinite 2s;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-bottom {
  animation: slide-bottom 1s ease-in-out;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-top {
  animation: slide-top 0.6s ease-in-out;
}

@keyframes slide-top {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-right {
  animation: slide-right 1s ease-in-out;
}

@keyframes slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-left {
  animation: slide-left 1s ease-in-out;
}

@keyframes slide-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
